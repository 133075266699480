<template>
  <div class="wish-detail">
    <!-- <van-nav-bar title="认领心愿" left-arrow @click-left="onClickLeft" /> -->
    <div class="wish-detail-body">
      <div class="noticeLine">
        <span class="marginLeft10">页面提示：如果你是梅城党员请填写注册钉钉手机号</span>
      </div>
      <div class="form">
        <van-cell-group>
          <van-field
            v-model="username"
            center
            clearable
            label="认领人"
            required
            ref="user"
            placeholder="请输入姓名"
          ></van-field>
          <van-field v-model="phone" required label="手机号" placeholder="请输入手机号" />
          <van-field
            v-model="content"
            rows="4"
            autosize
            label="备注"
            type="textarea"
            placeholder="备注内容"
          />
        </van-cell-group>
      </div>
      <button v-if="buttonShow" class="post" @click="confirmGet">确认认领</button>
      <button v-else class="postDisabled">确认认领</button>
    </div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import { formateTime } from '@/utils/util';
import { screenTinyWishCatch } from '@/api/bigScreen';
export default {
  data() {
    return {
      // minDate: new Date(2020, 0, 1),
      // maxDate: new Date(2025, 10, 1),
      // currentDate: new Date(),
      username: '',
      phone: '',
      content: '',
      id: 0,
      //图片
      fileList: [],
      buttonShow: true
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //确认认领
    confirmGet() {
      let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!reg.test(this.phone)) {
        Notify({ type: 'warning', message: '请输入正确的手机格式' });
        return false;
      }
      if (!this.username) {
        Notify({ type: 'warning', message: '请输入认领人姓名' });
        return false;
      }
      if (!this.phone) {
        Notify({ type: 'warning', message: '请输入手机号' });
        return false;
      }
      screenTinyWishCatch({
        id: this.id,
        givePersonnelName: this.username,
        givePersonnelMobile: this.phone,
        feedback: this.content
      }).then(res => {
        if (res.code == 200) {
          Notify({ type: 'success', message: '认领成功' });
          this.buttonShow = false;
          // setTimeout(() => {
          //   this.$router.go(-1);
          // }, 500);
          return false;
        }
        if (res.code == 10041) {
          Notify({ type: 'warning', message: res.msg });
        }
      });
    }
  },
  created() {
    // this.username = JSON.parse(localStorage.getItem('user')).realName;
    // this.phone = JSON.parse(localStorage.getItem('user')).phone;
    this.id = this.$route.query.id;
    console.log(this.id);
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.wish-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .wish-detail-body {
    // background: #f3f3f3;
    flex: 1;
    overflow: auto;
    .form {
      margin-top: 10 * $px;
      background: #fff;
      .van-uploader {
        padding: 24 * $px 0 30 * $px 24 * $px;
      }
    }
    .post {
      width: 690 * $px;
      height: 88 * $px;
      background: rgba(255, 25, 47, 1);
      border: 0;
      font-size: 32 * $px;
      color: rgba(255, 255, 255, 1);
      border-radius: 44 * $px;
      margin: 40 * $px 30 * $px;
    }
    .postDisabled {
      width: 690 * $px;
      height: 88 * $px;
      background: rgb(213, 214, 217);
      border: 0;
      font-size: 32 * $px;
      color: rgb(133, 134, 136);
      border-radius: 44 * $px;
      margin: 40 * $px 30 * $px;
    }
  }
}
.noticeLine {
  width: 100%;
  height: 50 * $px;
  line-height: 50 * $px;
  font-size: 24 * $px;
  color: rgb(45, 157, 250);
}
.marginLeft10 {
  margin-left: 20 * $px;
}
</style>